function tempCalc(pm25: number, minValue: number, maxValue: number, lowerLimit: number, upperLimit: number): number {
  const ratio = (lowerLimit - (pm25 - upperLimit)) / lowerLimit
  return minValue + (maxValue - minValue) * ratio
}

export function calculateIAQ(pm25: number): number {
  const TopLimit = 0
  const GreatLimit = 1
  const GoodLimit = 5
  const InferiorLimit = 15
  const PoorLimit = 25
  const CriticalLimit = 35

  if (pm25 <= GreatLimit) {
    return tempCalc(pm25, 83, 100, GreatLimit, TopLimit)
  }

  if (pm25 <= GoodLimit) {
    return tempCalc(pm25, 66, 83, GoodLimit, GreatLimit)
  }

  if (pm25 <= InferiorLimit) {
    return tempCalc(pm25, 50, 66, InferiorLimit, GoodLimit)
  }

  if (pm25 <= PoorLimit) {
    return tempCalc(pm25, 33, 50, PoorLimit, InferiorLimit)
  }

  if (pm25 <= CriticalLimit) {
    return tempCalc(pm25, 16, 33, CriticalLimit, PoorLimit)
  }

  if (pm25 >= CriticalLimit) {
    return tempCalc(pm25, 0, 16, 50, CriticalLimit)
  }
  return 1
  /*
  const C2 = pm25

  const C16 = 35
  const C17 = 25
  const C18 = 15
  const C19 = 10
  const C20 = 5

  const D9 = 0.2
  const E9 = C2 > C16 ? 0 : (C2>C17 ? 1 : (C2>C18 ? 2 : (C2>C19 ? 3 : (C2>C20 ? 4 : 5))))
  const F9 = E9 * D9
  // const G9 = C2 <= 5 ? 0.1 : 0
  const H9 = C2 <= C20 ? C20 : C2 <= C19 ? C19 : C2 <= C18 ? C18 : C2 <= C17 ? C17 : C2 <= C16 ? C16 : C2
  const I9 = C2 > C16 ? C16 : C2 > C17 ? C17 : C2 > C18 ? C18 : C2 > C19 ? C19 : C2 > C20 ? C20 : 0
  const L9 = (C2 - I9)/(H9 - I9)
  const M9 = L9 === 0 ? 0 : 0.2-(0.2*L9)
  const N9 = F9 === 1 ? F9 : F9 + M9

  console.log('E9: ' + E9)
  console.log('H9: ' + H9)
  console.log('I9: ' + I9)
  console.log('H9 - I9: ' + (H9 - I9))
  console.log('L9: ' + L9)
  console.log('M9: ' + M9)
  console.log('N9: ' + N9)

  return N9 * 100 
  */
}
