import {
  DEFAULT_GREAT_COLOR,
  DEFAULT_GOOD_COLOR,
  DEFAULT_INFERIOR_COLOR,
  DEFAULT_POOR_COLOR,
  DEFAULT_CRITICAL_COLOR,
  DEFAULT_SEVERE_COLOR,
} from '../../constants/defaults'
import { getColor } from '../../utils/getColor'

export interface Props {
  percentage: number
}

export function PercentageCircleSVG({ percentage }: Props) {
  // percentage = 62
  percentage = percentage < 0 ? 1 : percentage > 100 ? 100 : percentage
  // Logic to place the Gauge circle marker
  const actualMeasurement = percentage // actual measurement default value

  if (percentage) {
    if (percentage >= 83) {
      percentage = 100
    } else if (percentage >= 66 && percentage <= 83) {
      percentage = 83
    } else if (percentage >= 50 && percentage <= 66) {
      percentage = 66
    } else if (percentage >= 33 && percentage <= 50) {
      percentage = 50
    } else if (percentage >= 16 && percentage <= 33) {
      percentage = 33
    } else {
      percentage = 16
    }
  }

  const radius = 100
  const strokeWidth = radius * 0.08
  const backgroundStrokeWidth = strokeWidth * 0.055
  const circumference = 2 * radius * Math.PI
  const dash = (percentage * circumference) / 100
  const viewBoxSize = (radius + strokeWidth) * 2
  const viewBox = `0 0 ${viewBoxSize} ${viewBoxSize}`
  const shadowLength = percentage > 10 ? 40 : percentage * 4
  const highlightLength = percentage > 10 ? 100 : percentage * 4

  const path1 = `M ${viewBoxSize / 2} ${strokeWidth} a ${radius} ${radius} 0 0 1 ${radius} ${radius}`
  const path2 = `M ${viewBoxSize / 2} ${strokeWidth} a ${radius} ${radius} 0 0 0 ${-radius} ${radius}`

  const color = getColor(actualMeasurement)

  return (
    <svg width='100%' height='100%' viewBox={viewBox}>
      <defs>
        <linearGradient id='shadow' x1='0%' x2={`${shadowLength}%`}>
          <stop offset='0%' stopColor='black' stopOpacity='25%' />
          <stop offset='100%' stopColor='transparent' />
        </linearGradient>
        <linearGradient id='highlight' x1={`${100 - highlightLength}%`} x2='100%'>
          <stop offset='0%' stopColor={color} stopOpacity='0%' />
          <stop offset='100%' stopColor='white' stopOpacity='65%' />
        </linearGradient>
      </defs>
      <circle fill='none' stroke='#fff' cx='50%' cy='50%' r={radius} strokeWidth={`${backgroundStrokeWidth}`} />
      <circle
        fill='none'
        stroke={color}
        cx='50%'
        cy='50%'
        r={radius}
        strokeWidth={`${strokeWidth}`}
        transform={`rotate(-90 ${viewBoxSize / 2} ${viewBoxSize / 2})`}
        strokeDasharray={`${dash} ${circumference - dash}`}
        strokeLinecap='round'
      />
      <path d={path1} stroke='url(#shadow)' fill='none' strokeWidth={`${strokeWidth}`} strokeLinecap='round' />
      {percentage > 0 && (
        <path
          d={path2}
          stroke='url(#highlight)'
          fill='none'
          strokeLinecap='round'
          strokeWidth={`${strokeWidth}`}
          transform={`rotate(${(percentage / 100) * 360} 
          ${viewBoxSize / 2} ${viewBoxSize / 2})`}
        />
      )}
      <circle
        fill='#B9C4C6'
        stroke='#B9C4C6'
        cx='50%'
        cy='50%'
        r={strokeWidth / 2}
        transform={`translate(0 ${-radius})`}
      />
    </svg>
  )
}
