import {
  DEFAULT_AVERAGE_COLOR,
  DEFAULT_EXECELLENT_COLOR,
  DEFAULT_FAIR_COLOR,
  DEFAULT_GOOD_COLOR,
  DEFAULT_POOR_COLOR,
  DARK_AVERAGE_COLOR,
  DARK_EXECELLENT_COLOR,
  DARK_FAIR_COLOR,
  DARK_GOOD_COLOR,
  DARK_POOR_COLOR,
  DEFAULT_THRESHOLD_AVERAGE,
  DEFAULT_THRESHOLD_EXCELLENT,
  DEFAULT_THRESHOLD_FAIR,
  DEFAULT_THRESHOLD_GOOD,
  DEFAULT_CRITICAL_COLOR,
  DEFAULT_CRITICAL_THRESHOLD,
  DEFAULT_GOOD_THRESHOLD,
  DEFAULT_GREAT_COLOR,
  DEFAULT_GREAT_THRESHOLD,
  DEFAULT_INFERIOR_COLOR,
  DEFAULT_INFERIOR_THRESHOLD,
  DEFAULT_POOR_THRESHOLD,
  DEFAULT_SEVERE_COLOR,
} from '../constants/defaults'

interface rgbColor {
  r: number
  g: number
  b: number
}

function hexColorToRgbColor(hex: string): rgbColor {
  return {
    r: parseInt(hex.substring(1, 3), 16),
    g: parseInt(hex.substring(3, 5), 16),
    b: parseInt(hex.substring(5, 7), 16),
  }
}

function gradientColor(colorA: rgbColor, colorB: rgbColor, normalValue: number): rgbColor {
  return {
    r: Math.round(colorA.r + (colorB.r - colorA.r) * normalValue),
    g: Math.round(colorA.g + (colorB.g - colorA.g) * normalValue),
    b: Math.round(colorA.b + (colorB.b - colorA.b) * normalValue),
  }
}

export function getColor(percentage: number, useDark?: boolean): string {
  /*
  if (percentage > DEFAULT_THRESHOLD_EXCELLENT) {
    return useDark ? DARK_EXECELLENT_COLOR : DEFAULT_EXECELLENT_COLOR
  }

  if (percentage > DEFAULT_THRESHOLD_GOOD) {
    return useDark ? DARK_GOOD_COLOR : DEFAULT_GOOD_COLOR
  }

  if (percentage > DEFAULT_THRESHOLD_AVERAGE) {
    return useDark ? DARK_AVERAGE_COLOR : DEFAULT_AVERAGE_COLOR
  }

  if (percentage > DEFAULT_THRESHOLD_FAIR) {
    return useDark ? DARK_FAIR_COLOR : DEFAULT_FAIR_COLOR
  }
  return useDark ? DARK_POOR_COLOR : DEFAULT_POOR_COLOR
*/
  if (percentage >= DEFAULT_GREAT_THRESHOLD) {
    return DEFAULT_GREAT_COLOR
  }

  if (percentage >= DEFAULT_GOOD_THRESHOLD) {
    return DEFAULT_GOOD_COLOR
  }

  if (percentage >= DEFAULT_INFERIOR_THRESHOLD) {
    return DEFAULT_INFERIOR_COLOR
  }

  if (percentage >= DEFAULT_POOR_THRESHOLD) {
    return DEFAULT_POOR_COLOR
  }

  if (percentage >= DEFAULT_CRITICAL_THRESHOLD) {
    return DEFAULT_CRITICAL_COLOR
  }
  return DEFAULT_SEVERE_COLOR
}

export function getGradientColor(percentage: number): string {
  const value = percentage / 100
  const stops = [
    hexColorToRgbColor(DEFAULT_POOR_COLOR),
    hexColorToRgbColor(DEFAULT_FAIR_COLOR),
    hexColorToRgbColor(DEFAULT_AVERAGE_COLOR),
    hexColorToRgbColor(DEFAULT_GOOD_COLOR),
    hexColorToRgbColor(DEFAULT_EXECELLENT_COLOR),
  ]
  const stopLength = 1 / (stops.length - 1) // 0.25
  const valueRatio = value / stopLength
  const stopIndex = Math.floor(valueRatio)

  if (stopIndex <= 0) {
    const minColor = stops[0]
    return `rgb(${minColor.r}, ${minColor.g}, ${minColor.b})`
  }
  if (stopIndex >= stops.length - 1) {
    const maxColor = stops[stops.length - 1]
    return `rgb(${maxColor.r}, ${maxColor.g}, ${maxColor.b})`
  }

  const color = gradientColor(stops[stopIndex], stops[stopIndex + 1], valueRatio % 1)

  return `rgb(${color.r}, ${color.g}, ${color.b})`
}
